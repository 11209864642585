// This is from https://gist.github.com/zackdotcomputer/d7af9901e7db87364aad7fbfadb5c99b
import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import Link, { LinkProps } from 'next/link';

type PropTypes = LinkProps & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;
export type LinkToProps = PropsWithChildren<PropTypes>;

/// A unified component for the next/link <Link> and a standard <a> anchor.
/// Will lift href and all other props from Link up to the Link.
/// Will automatically make an <a> tag containing the children and pass it remaining props.
function LinkTo({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  locale,
  ...anchorProps
}: LinkToProps): JSX.Element {
  // These props are lifted up to the `Link` element. All others are passed to the `<a>`
  return (
    <Link {...{ href, as, replace, scroll, shallow, prefetch, locale }} {...anchorProps}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {children}
    </Link>
  );
}

export default LinkTo;
