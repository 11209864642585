import { PackageComponents } from '@whitelabel/component-library/src/context/PackageComponents/types';
import NestJSScrollToAnchor from '../components/NestJSScrollToAnchor';
import LinkTo, { LinkToProps } from '../components/LinkTo';

const packageComponents: PackageComponents['xcoverShared'] = {
  scrollToAnchor: ({ anchor, gap }: { anchor?: string; gap?: number }) => NestJSScrollToAnchor({ anchor, gap }),
  linkTo: ({ children, href, ...props }: LinkToProps) => LinkTo({ children, href, ...props }),
};

export default packageComponents;
