import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { smoothScrollToAnchor } from '@whitelabel/helpers/url';

interface INestJSScrollToAnchor {
  anchor?: string;
  gap?: number;
}

const NestJSScrollToAnchor = ({ anchor, gap }: INestJSScrollToAnchor): null => {
  const { asPath } = useRouter();
  useEffect(() => {
    if (anchor && RegExp(`#${anchor}`).exec(asPath)) {
      smoothScrollToAnchor(`#${anchor}`, gap)();
    }
  }, [anchor, gap, asPath]);
  return null;
};

export default NestJSScrollToAnchor;
