import React, { ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { INavItem } from '@whitelabel/helpers/types';

import messages from '../messages';
import { StyledNavItem, StyledNavLink, StyledNavIcon } from './styledNavItem';

export interface INavItemProps {
  item: INavItem;
  onClick?: ReactEventHandler;
}

const NavItem = ({ item: { messageKey, name, path, activePaths, icon }, onClick }: INavItemProps): JSX.Element => {
  const intl = useIntl();
  const location = useLocation();
  return (
    <StyledNavItem data-test-id="NavItem">
      <StyledNavLink
        to={`/${intl.locale}${path}`}
        exact
        active={
          activePaths
            ? !!activePaths.find((activePath: string) => location.pathname.startsWith(`/${intl.locale}${activePath}`))
            : undefined
        }
        data-test-id="NavItem-link"
        data-analytics={`navbar${messageKey || name}`}
        onClick={onClick}
      >
        {icon ? <StyledNavIcon as={icon} /> : ''}
        {messageKey ? intl.formatMessage(messages[messageKey as keyof typeof messages]) : name}
      </StyledNavLink>
    </StyledNavItem>
  );
};

export default NavItem;
