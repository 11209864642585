import * as FullStory from '@fullstory/browser';
import * as SentryNextJS from '@sentry/nextjs';
import { NextRouter } from 'next/router';
import { IError } from '@whitelabel/helpers/types';
import { checkValidHash } from '@whitelabel/helpers/url';
import { isOnServer } from '@whitelabel/helpers/utils';

export function captureExceptionWithFullStory(error: IError, extra?: Record<string, any>): void {
  // no FS on server or when headless browser
  if (!isOnServer && !window.isHeadless) {
    SentryNextJS.setTag(
      'FS Session',
      FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'FS Not Ready',
    );
  }
  if (error.status) {
    SentryNextJS.setTag('error_status', error.status);
  }
  if (error.code) {
    SentryNextJS.setTag('error_code', error.code);
  }
  if (error.requestId) {
    SentryNextJS.setTag('x-request-id', error.requestId);
  }
  if (extra?.url) {
    SentryNextJS.setTag('api_url', extra.url);
  }
  SentryNextJS.captureException(
    error,
    extra && {
      extra,
    },
  );
}

export const removeInvalidHash = (router: NextRouter) => {
  const { asPath, push } = router;
  const splitFromHash = asPath.split(/#(.*)/);
  const isValidHash = checkValidHash(splitFromHash[1]);

  if (!isValidHash) push(splitFromHash[0]);
  return isValidHash;
};
