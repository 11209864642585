import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import formMessages from '@whitelabel/helpers/forms/messages';
import commomMessages from '@whitelabel/helpers/messages/commonMsg';
import { PERMITTED_COUNTRY_CODE, INVALID_QUOTES } from '@whitelabel/helpers/constants';
import { useGoogleAPI } from '@whitelabel/helpers/hooks';
import Alert from '../Alert';
import FormikFormField from '../FormikFormField';
import Modal from '../Modal';
import Button from '../Button';
import Loading from '../Loading';
import { StyledHr } from './styledUpdateAddressModal';
import messages from './messages';

export interface IUpdateAddressModalProps {
  isOpen: boolean;
  toggle: (...args: any[]) => any;
  update: (...args: any[]) => any;
  disableManualAddressInput?: boolean;
}

const UpdateAddressModal = ({
  isOpen,
  toggle,
  update,
  disableManualAddressInput = false,
}: IUpdateAddressModalProps): JSX.Element => {
  const intl = useIntl();
  const googleAPILoaded = useGoogleAPI();

  const [error, setError] = useState('');

  const initialValues = { address: {} };

  const getSchema = () => {
    let schema = object({
      country: string().matches(new RegExp(PERMITTED_COUNTRY_CODE), intl.formatMessage(messages.locationNotSupported)), // nosemgrep
      postcode: string().required(intl.formatMessage(messages.postcodeRequired)),
    });
    schema = schema.test('address', ' ', (value) => {
      if (value?.country !== PERMITTED_COUNTRY_CODE) {
        setError(intl.formatMessage(formMessages.locationError));
        return false;
      }
      setError('');
      return true;
    });
    return schema;
  };

  const validationSchema = object({
    address: getSchema(),
  });

  const onSubmit = async ({ address }: any, { setSubmitting, setFieldError }: any) => {
    if (error) setError('');
    try {
      await update(address);
      toggle();
    } catch (errors) {
      if (errors === INVALID_QUOTES) {
        setFieldError('address', intl.formatMessage(messages.locationNotSupported));
        setError(intl.formatMessage(formMessages.locationError));
      } else {
        setError(intl.formatMessage(commomMessages.error));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onToggle = () => {
    if (error) setError('');
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      headingTag="h2"
      header={intl.formatMessage(messages.header)}
      body={
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              {error && <Alert color="danger">{error}</Alert>}
              <p>{intl.formatMessage(messages.body)}</p>
              {googleAPILoaded ? (
                <Field
                  component={FormikFormField}
                  id="updateAddress"
                  name="address"
                  type="address"
                  placeholder="Enter your address"
                  setGlobalError={setError}
                  disableManualInput={disableManualAddressInput}
                />
              ) : (
                <Loading />
              )}
              <StyledHr />
              <Button
                type="submit"
                disabled={!isValid || !dirty}
                loading={isSubmitting}
                data-analytics="updateAddress"
              >
                {intl.formatMessage(messages.updateButton)}
              </Button>
            </Form>
          )}
        </Formik>
      }
      toggle={onToggle}
    />
  );
};

export default UpdateAddressModal;
