import React from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from '@whitelabel/helpers/i18n';
import { CURRENCIES } from '@whitelabel/helpers/constants';
import { StyledPrice, StyledAnnualCost } from './styledQuoteWeeklyPrice';
import messages from './messages';

export interface IQuoteWeeklyPriceProps {
  className?: string;
  totalPrice: string | number;
}

const QuoteWeeklyPrice = ({ className, totalPrice }: IQuoteWeeklyPriceProps): JSX.Element => {
  const intl = useIntl();
  const weeklyPrice = +totalPrice / 52;
  return (
    <div className={className}>
      <StyledPrice>
        {formatCurrency(intl.formatNumber, weeklyPrice, CURRENCIES.USD)}
        <sub>{intl.formatMessage(messages.weekEquivText, { break: <br key="week-equiv-break" /> })}</sub>
      </StyledPrice>
      <StyledAnnualCost>
        {intl.formatMessage(messages.annualCostText, {
          annualCost: formatCurrency(intl.formatNumber, totalPrice, CURRENCIES.USD),
          break: <br key="annual-cost-break" />,
        })}
      </StyledAnnualCost>
    </div>
  );
};

export default QuoteWeeklyPrice;
