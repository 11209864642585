/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { IncomingMessage, ServerResponse } = require('http');
const pino = require('pino');
const { v4: uuidv4 } = require('uuid');

// logging properties
const REQUEST_ID = 'request_id';
const REQUEST_METHOD = 'request_method';
const REQUEST_HTTP_VERSION = 'request_http_version';
const REQUEST_PATH = 'request_path';
const REQUEST_CONTENT_MIME_TYPE = 'request_content_mime_type';
const REQUEST_HEADERS = 'request_headers';
const RESPONSE_STATUS = 'response_status';
const RESPONSE_HEADERS = 'response_headers';
const RESPONSE_CONTENT_MIME_TYPE = 'response_content_mime_type';

// request_id passed in headers
const X_REQUEST_ID = 'x-request-id';

const logger = pino({
  redact: {
    paths: ['password'], // we can add more as the nextjs app expand
    censor: '**REDACT**',
  },
  base: {
    pid: undefined, // remove default pid logging behavior
    application: 'xcover-nextjs',
    env: process.env.NODE_ENV || process.env.NEXT_PUBLIC_ENV || 'unset',
  },
  messageKey: 'message',
  timestamp: () => `,"timestamp":"${Date.now()}"`, // change the label time to timestamp
  formatters: {
    level(label) {
      return { level: label };
    },
  },
});

/**
 * Function to generate request_id in request header and log request & response use pino
 * @param { IncomingMessage | undefined } req request/incoming message for server
 * @param { ServerResponse | undefined } res response/outgoing message from server
 */
function pinoLog(req, res) {
  if (req) {
    const requestID = req.headers[X_REQUEST_ID];
    if (!requestID) {
      req.headers[X_REQUEST_ID] = uuidv4();
    }
    if (res && process.env.NODE_ENV !== 'development') {
      logger.info({
        [REQUEST_ID]: req.headers[X_REQUEST_ID],
        [REQUEST_PATH]: req.url,
        [REQUEST_METHOD]: req.method,
        [REQUEST_CONTENT_MIME_TYPE]: req.headers['content-type'],
        [REQUEST_HTTP_VERSION]: req.httpVersion,
        [REQUEST_HEADERS]: JSON.stringify(req.headers),
        [RESPONSE_STATUS]: res.statusCode,
        [RESPONSE_CONTENT_MIME_TYPE]: res.getHeader('content-type'),
        [RESPONSE_HEADERS]: JSON.stringify(res.getHeaders()),
      });
    }
  }
}

/**
 * Function to get an RequestInit object containing headers['x-request-id']
 * @param { IncomingMessage | undefined } req request/incoming message for server
 * @returns { RequestInit | {}}
 */
function getRequestIDInit(req) {
  if (req?.headers?.[X_REQUEST_ID]) {
    return {
      headers: {
        [X_REQUEST_ID]: req.headers[X_REQUEST_ID],
      },
    };
  }
  return {};
}

logger.pinoLog = pinoLog;
logger.getRequestIDInit = getRequestIDInit;

module.exports = logger;
