import { useEffect } from 'react';

type Props = {
  setGlobalError: (...args: any[]) => any;
  errors: {
    [key: string]: any;
  };
};

const GlobalErrorCleaner = ({ setGlobalError, errors }: Props) => {
  useEffect(() => {
    if (Object.keys(errors).length === 0) setGlobalError();
  }, [errors, setGlobalError]);

  return null;
};

export default GlobalErrorCleaner;
