import React from 'react';
import { Media } from '@whitelabel/helpers/media';
import { ITableProps, IBaseTableRow, ITableColumn } from './types';
import { StyledTable, StyledList, StyledAlert } from './styledTable';
import { verifyAndRenderNode } from './helpers';

const Table = <T extends IBaseTableRow>({
  columns,
  rows,
  renderBodyCell: customRenderBodyCell,
  alertMessage,
}: ITableProps<T>): JSX.Element => {
  const renderHeadCell = (col: ITableColumn<T>) => col?.fieldTitle;

  const renderBodyCell = (row: T, col: ITableColumn<T>) => {
    const defaultRender = verifyAndRenderNode(row?.[col.fieldKey]);
    const customRender = customRenderBodyCell?.(
      {
        row,
        col,
      },
      defaultRender,
    );
    return customRenderBodyCell ? customRender : defaultRender;
  };

  return (
    <>
      <Media greaterThanOrEqual="sm">
        <StyledTable>
          <thead className="table-head-container">
            <tr>
              {columns.map((col) => (
                <th
                  className="table-head-cell"
                  key={col.fieldKey.toString()}
                  {...(col?.fieldWidth && { width: col.fieldWidth })}
                >
                  {renderHeadCell(col)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                {columns.map((col) => (
                  <td className="table-body-cell" key={col.fieldKey.toString()}>
                    {renderBodyCell(row, col)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
        {alertMessage && <StyledAlert color="info">{alertMessage}</StyledAlert>}
      </Media>
      <Media lessThan="sm">
        <StyledList $alertMessage={!!alertMessage}>
          {rows.map((row) => (
            <li className="list-item" key={row.id}>
              {columns.map((col) => (
                <div className="list-item-row" key={col.fieldKey.toString()}>
                  <p className="list-head-cell">{renderHeadCell(col)}</p>
                  <p className="list-body-cell">{renderBodyCell(row, col)}</p>
                </div>
              ))}
            </li>
          ))}
        </StyledList>
        {alertMessage && <StyledAlert color="info">{alertMessage}</StyledAlert>}
      </Media>
    </>
  );
};

export default Table;
