import React from 'react';
import { useIntl } from 'react-intl';
import { SMALL_ICONS } from '@whitelabel/helpers/smallIcons';
import { StyledSellingPoint, StyledSellingPointsList } from './styledSellingPointsList';
import messages from './messages';

export interface ISellingPointsListProps {
  className?: string;
}

const SellingPointsList = ({ className }: ISellingPointsListProps): JSX.Element => {
  const intl = useIntl();

  const SELLING_POINTS = [
    { icon: SMALL_ICONS.phone, text: intl.formatMessage(messages.instantClaimPay) },
    { icon: SMALL_ICONS.guard, text: intl.formatMessage(messages.easyApproval) },
    { icon: SMALL_ICONS.tick, text: intl.formatMessage(messages.strongBackup) },
    { icon: SMALL_ICONS.dollar, text: intl.formatMessage(messages.protect) },
  ];

  return (
    <StyledSellingPointsList className={className}>
      {SELLING_POINTS.map(({ icon: Icon, text }) => (
        <StyledSellingPoint key={text}>
          <Icon />
          {text}
        </StyledSellingPoint>
      ))}
    </StyledSellingPointsList>
  );
};

export default SellingPointsList;
