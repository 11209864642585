import React, { ReactNode } from 'react';
import Button from '../Button';
import { StyledBannerContainer } from './styledCallToActionBanner';

export interface ICallToActionBannerProps {
  paragraph: ReactNode;
  buttonLabel: string;
  href: string;
  title?: string;
  image?: string;
}

const CallToActionBanner = ({ paragraph, buttonLabel, href, title, image }: ICallToActionBannerProps) => (
  <StyledBannerContainer $image={!!image}>
    {image && <img className="banner-image" src={image} alt="bannerImage" />}
    <div className="banner-content">
      {title && <h5 className="banner-title">{title}</h5>}
      <p className="banner-paragraph">{paragraph}</p>
    </div>
    <Button tag="a" className="banner-button" buttonType="primaryDark" href={href}>
      {buttonLabel}
    </Button>
  </StyledBannerContainer>
);

export default CallToActionBanner;
