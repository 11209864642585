import * as React from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { StyledBreadCrumb } from './styledBreadcrumb';

interface IItem {
  name: string;
  path: string;
}

export interface IBreadcrumbProps {
  items: IItem[];
  /**
   * Current path item is not included in breadcrumb items.
   */
  includeCurrentPath?: boolean;
}

const Breadcrumb = ({ items, includeCurrentPath = false }: IBreadcrumbProps) => {
  const { locale, bidi } = useIntl();
  let displayItems: IItem[];
  if (includeCurrentPath) {
    displayItems = items;
  } else {
    const emptyItemPlaceHolder = { name: '', path: '' };
    displayItems = [...items, emptyItemPlaceHolder];
  }

  return (
    <StyledBreadCrumb $rtl={bidi} data-test-id="Breadcrumb" className="breadcrumb-wrapper">
      {displayItems.map((item, index) => {
        const active = index === displayItems.length - 2;
        return (
          <BreadcrumbItem key={item.name} active={active} data-test-id="Breadcrumb-item">
            {active ? (
              <p>{item.name}</p>
            ) : (
              <Link to={`/${locale}${item.path}`} data-test-id="Breadcrumb-link">
                {item.name}
              </Link>
            )}
          </BreadcrumbItem>
        );
      })}
    </StyledBreadCrumb>
  );
};

export default Breadcrumb;
