import { routerReducer } from 'connected-next-router';
import { intlReducer as intl } from 'react-intl-redux';
import locales from '@whitelabel/reducers/locales';
import navs from '@whitelabel/xcover-www-frontend/src/reducers/navs';

const rootReducer = {
  router: routerReducer,
  intl,
  locales,
  navs,
};

export default rootReducer;
